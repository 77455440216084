/* eslint-disable no-const-assign */
import ChatBot, { BotOptionsContext, Flow, MessagesContext, Options, Params, PathsContext } from "react-chatbotify";
import { useBotOptions } from "../Context/BotOptions";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  getDefaultBotOptions,
} from "../Services/BotOptionServices";
import { ChatClient, ChatThreadClient, SendMessageOptions } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential, CommunicationIdentifier, CommunicationUserIdentifier } from "@azure/communication-common";
import axios from "axios";
import {
  ChatViewActionContextInfo,
  ChatViewContextInfo,
} from "../Context/ChatContextProvider";
import { TransactionViewContextInfo } from "../Context/TransactionContext";
import CloseModal from "../Components/Chat-Close-Modal";
import ServiceAgent from '../assets/customer-service-agent.png';
import closeChatIcon from "../assets/close_chat_icon.svg";
import "./Chat.css"; 
import CallWidget from "../Call-Widget";
import { Inviter, Messager, Registerer, Session, UserAgent } from "sip.js";
import { environment } from "../environments/environment.dev";

const ChatBotifyWidget = () => {
  // const botOptions = getDefaultBotOptions();
  const endpointUrl = "https://acs-ucx-dev-eus-001.unitedstates.communication.azure.com";
  const { showChatBot,setBotOptions,botOptions,showCall,setCall,setChatBot } = useBotOptions();
  const [chatClient, setChatClient] = useState<ChatClient | null>();
  const { accessToken, customerID,isDeleted,threadId} = ChatViewContextInfo();
  const { setAccessToken, setCustomerID ,setIsDeleted,setThreadId, setsipAgent,
    setsipReg,} = ChatViewActionContextInfo();

  const [isLoad,setIsLoad] = useState(true)
  const [messages, setMessages] = useState<any[]>([]);
  //const { QueueList } = TransactionViewContextInfo()
  const [chatThreadClient, setChatThreadClient] = useState<ChatThreadClient | null>(null);
  const [paths, setPath] = useState<string[]>([]);
  const [jobId, setJobId] = useState()
  const [endShown, setEndShown] = useState(false)
  const userAgentRef = useRef<UserAgent | null>(null);
  const registererRef = useRef<Registerer | null>(null);
  const sessionRef = useRef<Session | null | any>(null);
  const [callStatus, setCallStatus] = useState<string>('Initializing...');
  const [isMuted, setIsMuted] = useState(false);
  const APIURL = environment.APIURL
  const [isOnHold, setIsOnHold] = useState<boolean>(false);
  // const webSocketURL = 'wss://auss.sbnatech.in:7443';
  // const SipAgent1 = 'sip:1@auss.sbnatech.in'; // Caller
  // const SipAgent2 = 'sip:50000@auss.sbnatech.in'; // Callee
  // const password = "1182062516433635"
  // const userName = "1"
  let webSocketURL = "";
  let SipAgent1 = "";
  let SipAgent2 = "";
  let userName = ""
  let password = ""
  const [remoteUrl,setRemoteUri ] = useState("")
  const [remoteDisplayName,setDisplayName] = useState("UNKNOWN")
  const [QueueList, setQueueList] = useState([
   
  ])


  const initializeSIPUserAgent = (socket: WebSocket) => {
    console.log(password,userName,"Regsiter")
    const newdata:any = localStorage.getItem("Extension1")
    const data = JSON.parse(newdata)
   console.log(socket,"socket");
   
    const userAgentOptions = {
      //uri: UserAgent.makeURI(`${SipAgent1};username=Madhesh;`),
      uri: UserAgent.makeURI(data?.FromUri),
      transportOptions: {
        server: data.socket,
        connectionTimeout: 5000,
        traceSip: true,
        connection: socket,
      },
      authorizationUsername: data?.authorizationUsername,
      authorizationPassword:data?.authorizationPassword,
      displayName: 'Customer',    
    };

    const userAgent = new UserAgent(userAgentOptions);
    userAgentRef.current = userAgent;

    const registerer = new Registerer(userAgent);
    registererRef.current= registerer;

    userAgent.start().then(  () => {
      console.log('User Agent started');
      return registerer.register();
    }).then( async () => {
      setsipAgent(userAgent);
      setsipReg(registerer);
      console.log('Registered successfully');
     await makeCall()
      setCallStatus('Agent Registered');
    }).catch((error) => {
      console.error('Failed to start/register User Agent:', error);
      setCallStatus('Failed to register');
    });

    userAgent.delegate = {
      onMessage: (message:any) => {
        console.log(message,"messs");
        const fromURI = message.request.from.uri.toString();
        console.log("From URI:", fromURI);
        setRemoteUri(fromURI)
        const remoteDisplayName = message.request.from._displayName

    
        console.log('Incoming call from:', remoteDisplayName);
        setDisplayName(remoteDisplayName)
        const body = message.request.body
        console.log(body,"body")
        if (!body || body.trim() === "") {
          console.log("Received an empty message, not processing.");
          return;  // Exit early if the message is empty
        }
        handleChatMessageReceived1(message);
      },
    };
  };



  // const connectSockt = async () => {
  //   const data:any = localStorage.getItem("Extension1")
  //   console.log(JSON.parse(data),"datadata");
    
  //  if(!data) return
      
  //   const socket = new WebSocket(webSocketURL);

  //   socket.onopen = () => {
  //     console.log('WebSocket connection established');
  //     initializeSIPUserAgent(socket);
  //   };

  //   socket.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket connection closed');
  //   };

  //   return () => {
  //     if (registererRef.current) {
  //       registererRef.current.unregister();
  //     }
  //     if (userAgentRef.current) {
  //       userAgentRef.current.stop().then(() => {
  //         console.log('User Agent stopped');
  //         console.log("hi")
    
  //       }).catch((error) => {
  //         console.error('Failed to stop User Agent:', error);
  //       });
  //     }
  //     if (socket) {
  //       socket.close();
  //     }
  //   };
  // }

  const connectSockt = async () => {
    const newdata:any = localStorage.getItem("Extension1")
    const data = JSON.parse(newdata)
    console.log(data,"datadata");
    
   //if(!data) return
      
    const socket = new WebSocket(data?.socket);

    socket.onopen = () => {
      console.log('WebSocket connection established');
      initializeSIPUserAgent(socket);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      if (registererRef.current) {
        registererRef.current.unregister();
      }
      if (userAgentRef.current) {
        userAgentRef.current.stop().then(() => {
          console.log('User Agent stopped');
          console.log("hi")
    
        }).catch((error) => {
          console.error('Failed to stop User Agent:', error);
        });
      }
      if (socket) {
        socket.close();
      }
    };
  }


 // const [chatQueueValue, setchatQueueValue] = useState("")
let chatQueueValue = ""
  // useEffect(() => {
  //   setchatQueueValue(chatQueueValue)
  // }, [chatQueueValue])

  const makeCall = async () => {
    setAccessToken(null)
 console.log(SipAgent2,SipAgent1,userName,password);
 
    
    
       const uri = UserAgent.makeURI(SipAgent2);
  
    if (!uri) {
      console.error("Invalid URI for VoIP call");
      return;
    }
    const address = {
      street: "812 Aviation Way",
      city: "Ebro",
      apt: "Florida",
      phoneNumber: "213-806-7869",
      zipCode: "32437",
      countryCallingCode: "+1",
      County: "United States",
      email:"madhesh.c@co.in",
      customerName:"madhesh",
      customerId:"41"
    };
    
    console.log(address);
    
    if (userAgentRef.current) {
      // Define options with muted audio
      const options = {
        sessionDescriptionHandlerOptions: {
          constraints: { audio: false, video: false },  // Disable audio and video constraints
          offerOptions: {
            offerToReceiveAudio: true,  // Allow receiving remote audio
            offerToReceiveVideo: false, // Disable receiving video
          },
        },
        requestDelegate: {
          onAccept: (response: any) => {
              console.log(response,"Call accepted");
              
            setCallStatus('Call accepted');
          },
          onReject: (response: any) => {
              console.log(response,"rej");
            setCallStatus('Call rejected');
          }
        },
       // requestOptions: {
          extraHeaders: [
            'X-Custom-Header: Madhesh', // No need for extra quotes
         `X-Variable-Chat-Queue: ${chatQueueValue}`  ,
         `X-Variable-Chat-QueueID: ${chatQueueValue}` , // Properly formatted header
         `X-Customer-Address: ${JSON.stringify(address)}`  // Properly formatted header
          ]
      };
     
      console.log("Extra Headers:", options.extraHeaders);
      // Create a new Inviter instance with muted options
      const inviter = new Inviter(userAgentRef.current, uri, options);
  
      try {
        await inviter.invite().then((e)=>{
          setAccessToken(inviter)
          monitorSessionEvents(inviter)
          sessionRef.current = inviter;
         
        });
       // setupMessageListeners()
        console.log("VoIP call initiated successfully with muted audio.");
        setCallStatus("Chat Initiated");
      //  setMakechat(true)
        // Store the inviter in state if needed to manage the call later
      } catch (error) {
        console.error("Failed to initiate VoIP call:", error);
      }
    } else {
      console.error("UserAgent is not initialized.");
    }
  }

  const startJobExecution = async (params: Params) => {
    chatQueueValue = params.userInput
    getCutomerDetatils().then((e)=>{
      console.log("hi");
      return "chatDisconnect"
      
    }).catch((e)=>{
      console.log("error");
    })
    

    return
	setIsLoad(true)
     const filteredQueue:any = QueueList.filter((queue:any) => queue.QueueName.toLowerCase() === params.userInput.toLowerCase())[0]
    try {
      setAccessToken('');
      setCustomerID('');
    
      const responseData = await axios.get(
        "https://jsonip.com/"
      )
      // let responseData = await fetch('https://geolocation-db.com/json/');
      // let response = await responseData.json()
      const arugments = new URLSearchParams(window.location.search)
      let TenantId:any=arugments.get('TenantId')
      let LoginUserId:any=arugments.get('LoginUserId')
      let UniqueName:any=arugments.get('UniqueName')
      let WidgetInfo:any=arugments.get('widgetInfo')
      console.log(WidgetInfo)
      let obj = {
        tenantId:TenantId,
        queueId: filteredQueue.Id,
        channelId: filteredQueue.ChannelName,
        CreatedIp: responseData?.data.ip,
        WidgetCustomerName:UniqueName!=null ?UniqueName:'',
        WidgetInfo:WidgetInfo!=null?WidgetInfo:''
      };
      
      const data = await axios.post(
        "https://ucx-rtc-api-dev.azurewebsites.net/api/JobRouting/JobCreation",
        obj
      );

      if (data.data) {
        localStorage.clear();
        console.log(data.data);
        setAccessToken(data.data.accessToken);
        setCustomerID(data.data.userIdentity);
        localStorage.setItem('jobId', data.data.jobId);
      }
    } catch (e:any) {
      setAccessToken('');
      setCustomerID('');
      console.log(e);
      params.injectMessage(e.message);
	  setIsLoad(false)
    }


  };
  const abondedstatusChange = async () => {
    const params = new URLSearchParams(window.location.search)
   
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    let IdentifierCode:any=params.get('IdentifierCode')
     const URL =  APIURL + "CX/abondedstatusChange"
   const data=  await axios.post(URL,{callerId:LoginUserId + "_"+ IdentifierName,UniqueCode:IdentifierCode})
  // const data=  await axios.post(URL,{callerId:"1" + "_"+ "ALLCAREUS_ALLCAREUS"})      
  // const data =  await axios.post(URL,{callerId:"68808004-5000-48ab-8c82-fa170ffb2f2d" + "_" + "Madhesh"}) 
   console.log(data,"data")
      const responseDetails = await data.data.data
      console.log(responseDetails,"responseDetails")
      //getCutomerDetatils()


  }

  const getCutomerDetatils =  async () => {
    const params = new URLSearchParams(window.location.search)
    let IdentifierCode:any=params.get('IdentifierCode')
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    //if(!WidgetInfo)  return alert("customer details not found")
  
   // if (apiCalled.current) return;  
   
  const URL = APIURL + "CX/getCustomerDetails"
    //const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613",CustomerId:"68808004-5000-48ab-8c82-fa170ffb2f2d",CustomerName:"madhesh"})
     const data=  await axios.post(URL,{UniqueCode:IdentifierCode,CustomerId:LoginUserId,CustomerName:IdentifierName})
   //  const data=  await axios.post(URL,{UniqueCode:"ALLCAREUS_ALLCAREUS",CustomerId:"1",CustomerName:""})
    const responseDetails = await data.data.data
    if(data.data.statusCode == 400){
     // abondedstatusChange()
      alert(data.data.message)
      //startAgain()
     // setChatBot(false)
     setIsDeleted(true)
     //startAgain()
     window.location.reload()
      ///getTenantBasedQueue()
      return
     }
     else { 
      console.log(data.data.statusCode)
      console.log(responseDetails,"responseDetails")
      const socket = "wss://" + responseDetails.TenantDomain + ":7443"
       /// const socket = "ws://" + responseDetails.TenantDomain + ":5066"
      const FromUri ="sip:"+responseDetails.CustomerUserId+"@"+responseDetails.TenantDomain
      const ToUri = "sip:"+responseDetails.TenantExtenstion+"@"+responseDetails.TenantDomain
      const authorizationUsername =  responseDetails.CustomerUserId
      const authorizationPassword = responseDetails.TenantCode + "_"+responseDetails.CustomerUserId
       webSocketURL = socket;
       SipAgent1 = FromUri;
       SipAgent2 = ToUri;
       userName = authorizationUsername
       password = responseDetails.Key
     // SetwebSocketURL(socket);
       const  extension = {
        socket:socket,
        FromUri:FromUri,
        ToUri:ToUri,
        authorizationUsername:authorizationUsername,
        authorizationPassword:responseDetails.Key,
       }
       localStorage.setItem("Extension1",JSON.stringify(extension))
     
      await connectSockt()
     }
   }



   const getTenantBasedQueue =  async () => {
    setQueueList([])
    const params = new URLSearchParams(window.location.search)
    let IdentifierCode:any=params.get('IdentifierCode')
    let LoginUserId:any=params.get('LoginUserId')
    let IdentifierName:any=params.get('IdentifierName')
    //if(!WidgetInfo)  return alert("customer details not found")
  
   // if (apiCalled.current) return;  
  const URL = APIURL+"CX/getTenantBasedQueue"
    //const data=  await axios.post(URL,{TenantId:"4d5aeb8d-94ec-42cd-8a55-3fdf86814613",CustomerId:"68808004-5000-48ab-8c82-fa170ffb2f2d",CustomerName:"madhesh"})
    // const data=  await axios.post(URL,{UniqueCode:IdentifierCode,CustomerId:LoginUserId,CustomerName:IdentifierName})
    // const data=  await axios.post(URL,{TenantUniqueCode:"ALLCAREUS_ALLCAREUS",})
     const data=  await axios.post(URL,{TenantUniqueCode:IdentifierCode,})
    const responseDetails = await data.data.data
     console.log(responseDetails,"responseDetails");

     setQueueList(responseDetails.Queuename)
     

   }
  const handleIncomingMessage = (message: any) => {
    const body = message.request.body;
    const fromURI = message.from?.uri.toString();
    const fromDisplayName = message.from?.displayName || fromURI; // Extract the display name or use URI
    const timestamp = Date.now(); // Timestamp for uniqueness
    
    if (body) {
      if (body === "User is typing...") {
        // Handle typing notification
      //  setTypingIndicator(remoteDisplayName);
        return;
      }

      if (body === "Chat has ended") {
        // Handle chat end
        console.log(`Chat with ${remoteDisplayName} has ended.`);
        setCallStatus('Chat ended');
      //  handleEndChat();
       // setTypingIndicator(null); // Clear typing indicator
        return;
      }
 

      let messageContent = body;
      try {
        messageContent = JSON.parse(body);
        console.log(messageContent,"messageContent")
        messageContent = `Received JSON: ${messageContent}`;
      } catch (e) {
        messageContent = `${"Agent"} ${body}`;
        
      }

      const newMessage = { id: `msg-${timestamp}-${Math.random()}`, from: fromDisplayName, body: messageContent };

      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(msg => msg.body === newMessage.body && msg.from === newMessage.from && msg.id === newMessage.id);
        if (!isDuplicate) {
          return [...prevMessages, newMessage];
        }
        return prevMessages;
      });
    } else {
      console.error('Message content is undefined');
    }
  };
  const handleChatMessageReceived = async (chatMessage: any) => {
    console.log();
    
    // try {
      console.log("Notification received:", chatMessage);
      if (!chatMessage || !chatMessage.message) {
        console.log('Invalid notification format or missing message:', chatMessage);
        return;
      }

      const newThreadId = chatMessage.threadId;

      if (!newThreadId) {
        console.error('Thread ID not found in notification payload');
        return;
      }
      setThreadId(newThreadId);

      if (!newThreadId) {
        console.error('Thread ID not found in notification payload');
        return;
      }
      const chatThreadClients = chatClient!.getChatThreadClient(newThreadId ?? threadId);
      if (!chatThreadClients) {
        console.error('Error: Unable to get chatThreadClient');
        return;
      }
      setChatThreadClient(chatThreadClients);
      setEndShown(true)
      try {
        if(chatMessage.senderDisplayName === "user") return
        const senderDisplayName = chatMessage.senderDisplayName;
        const newMessageContent = chatMessage.message;
        setMessages(prev => {
          const newMessage = {
            content: newMessageContent,
            sender: senderDisplayName,
            type: "string",
          }
          return [...prev, newMessage]
        })
        console.log(messages.length)
        if(!messages.includes('enableChatInput')){
        setPath(prev => [...prev, "enableChatInput"]);
        }
      }catch (e) {

      }

  }

  const handleChatMessageReceived1 = async (message: any) => {
   console.log(message,"message");
   
    const body = message.request.body;
    const extract = JSON.parse(body)
    if(extract.body == ""){
      return
    }
    setJobId(extract.callID)
    const fromURI = message.from?.uri.toString();
    const fromDisplayName = message.from?.displayName || fromURI; // Extract the display name or use URI
    const timestamp = Date.now(); // Timestamp for uniqueness

   
      setEndShown(true)
      try {
        if(message.senderDisplayName === "user") return
        const senderDisplayName ="Agent";
        const newMessageContent =extract.body;
        setMessages(prev => {
          const newMessage = {
            content: newMessageContent,
            sender: senderDisplayName,
            type: "string",
          }
          return [...prev, newMessage]
        })
        console.log(messages.length)
        if(!messages.includes('enableChatInput')){
        setPath(prev => [...prev, "enableChatInput"]);
        }
      }catch (e) {

      }

  }

  const sendMessage1 = async (params: Params) => {

    try {
      // console.table(messages)
      const sendMessageRequest = {
        content: params.userInput,
        
      };
      const sendMessageOptions:SendMessageOptions = {
        senderDisplayName: 'user',
        type: 'text' as const,
      };
      await chatThreadClient?.sendMessage(sendMessageRequest,sendMessageOptions)
      return 
    }catch(e){
      console.log(e)
    }

  }

  const sendMessage = (message: Params) => {
    if(!message.userInput){
      
    return
    }
  //  const targetURI = UserAgent.makeURI("sip:59390@auss.sbnatech.in");
    const targetURI = UserAgent.makeURI(remoteUrl);
    console.log(targetURI,"tarrrrrrrrrr");
    const messageBody = {
      body: message.userInput,
      callID: jobId,
    };
    const messageBodyString = JSON.stringify(messageBody);
    if (!targetURI) {
      console.error('Invalid target URI');
      return;
    }
    // if(message.userInput === "End"){
    //   sessionRef.current.bye().then(() => { ;
    //           sessionRef.current = null;
    //           setCallStatus('Call Ended');
    //         }).catch((error: any) => {
    //           console.error('Failed to hang up the call:', error);
    //           setCallStatus('Failed to End Call');
    //         });
    //         return
    // }

    if (userAgentRef.current) {
      const messager = new Messager(userAgentRef.current, targetURI, messageBodyString);
      messager.message();
      // if(!messages.includes('enableChatInput')){
      //   setPath(prev => [...prev, "enableChatInput"]);
      //   }
     // const newMessage = { id: `msg-${Date.now()}-${Math.random()}`, from: 'Me', body: message };
      //setMessages((prevMessages) => [...prevMessages, newMessage]);
     // setCallStatus('Chatting');
    }
  };

  
  const monitorSessionEvents = (session: Session | any) => {
    if(!session)return
    // Detect when the remote user hangs up
    session.delegate = {
      onBye: () => {
  //setCallStatus('Call Ended by Remote');
        //toast.error("Call Ended By Customer")
        startAgain()
        setCallStatus('Call Ended by agent');
        setAccessToken(null)
        setsipAgent(null)
        setsipReg(null)
      },
      onTerminated: () => {
        setAccessToken(null)
        setsipAgent(null)
        setsipReg(null)
        startAgain()
      },
    };
 
  

    
  };
  const startAgain = useCallback(async () => {
    setPath([]);
    setMessages([]);
    setThreadId('')
    setEndShown(false);
    localStorage.clear();
    setPath(prev => [...prev, 'start']);
  }, []);

  useEffect(() => {
    const handleStartAgain = async () => {
      if (isDeleted) {
        await startAgain();
      }
    };
    handleStartAgain();

    return () => {
      setIsDeleted(false);
    };
  }, [isDeleted]);
  

  useEffect(() => {
    //connectSockt();
    getTenantBasedQueue()
    //abondedstatusChange()
  //  getCutomerDetatils()
    return () => {
      
    }
  }, [])
  

  const flow: Flow = {
 
    start: {
      message: "Hello! How can I assist you today?",
      options: QueueList.map((s:any) => { return s}),
     //options: QueueList.filter((item, index) => QueueList.indexOf(item) === index).map((s:any) => { return s}),
      path: "ask_customer_Connect",
      chatDisabled: true,
    },
    ask_customer_Connect: {
      message: (params: Params) => {
        startJobExecution(params);
       
        return params.injectMessage(
          `We are connecting you to our ${params.userInput} Agent to you! Please hold for a few seconds...`
        );
      },
     chatDisabled: true,
      render: (params: Params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2rem",
            gap: 4,
            margin: "1rem 1rem 0rem 1rem",
            height: 40,
            backgroundColor: "whitesmoke",
          }}
        >
          <span>Connecting to {params.userInput} Agent...</span>
        </div>
      ),
      //path: "ask_connect_agent",
      path: async (params: Params): Promise<string | null | undefined  | any> => {
        try {
          const nextPath = await startJobExecution(params);
          return nextPath; // Ensure the result matches the expected type
        } catch (error) {
          console.error("Error in path resolution:", error);
          return "chatDisconnect"; // Failover to disconnect path
        }
      },
    },
    ask_connect_agent: {
      message: async (params) => {
      	await  sendMessage(params)
      },
      chatDisabled:false,
      path:'ask_connect_agent'
    },
    enableChatInput:{
      chatDisabled:false,
      path:'ask_connect_agent'
    },
    chatDisconnect:{
      chatDisabled: true,
      render: (params: Params) => (
        <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2rem",
            gap: 4,
            margin: "1rem 1rem 0rem 1rem",
            height: 20,
            backgroundColor: "whitesmoke",
          }}
        >
          <span style={{fontSize:12}}>Agent Disconnected..</span>
        </div>
         <button className="btn-1" onClick={startAgain}>Start Again</button></div>
      ),
    },
  };

  return (
    <div>
      	<BotOptionsContext.Provider value={{botOptions: botOptions, setBotOptions: setBotOptions}}>
      	<PathsContext.Provider value={{paths: paths, setPaths: setPath}}>
          <MessagesContext.Provider value={{messages: messages, setMessages: setMessages}}>
           {showChatBot ? 
           <ChatBot flow={flow} options={{
            ...botOptions,
            isOpen:showChatBot,
            header:{
              title:<CloseModal />,
              showAvatar: true,
              avatar: ServiceAgent,
              closeChatIcon: closeChatIcon,
            },advance: {
              ...botOptions.advance,
              useCustomBotOptions: false,
              useCustomPaths: true,
              useCustomMessages: true,
      
            },
            footer:{
              text:(
                <div style={{cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", columnGap: 3}} 
                  onClick={() => window.open("https://sbnasoftware.com/contact")}
                >
                  {/* <span>Powered By</span> */}
                  {/* <img style={{width: 10, height: 10}} src={chatButton}/> */}
                  {/* <span style={{fontWeight: "bold"}}>SBNA Software Solutions</span> */}
                </div>
              ),
            },
            chatInputAreaStyle: {
              // resize: "both", // Allow both horizontal and vertical resizing
              // maxHeight: "150px", // Set minimum height
          
          },
           }} />
           : undefined}
           {showCall ?
           <CallWidget/>: undefined
           } 
        </MessagesContext.Provider>
        </PathsContext.Provider>
        </BotOptionsContext.Provider>

    </div>
  );
};

export default ChatBotifyWidget;
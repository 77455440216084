import { ChatViewActionContextInfo, ChatViewContextInfo } from '../../Context/ChatContextProvider';
import ChatBotWidget from './ChatBotWidget';
import React, { useEffect, useMemo, useState } from "react";
// import axios from '../../Services/axios';
import { ChatClient, ChatThreadClient, CreateChatThreadResult,SendMessageOptions } from '@azure/communication-chat';
import axios from 'axios';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

const ChatWidget = () => {

    const [chatOpen,isSetChatOpen] = useState(false)
    const [chatClient, setChatClient] = useState<ChatClient>();
    const [chatThreadClient, setChatThreadClient] = useState<ChatThreadClient>();
    const { accessToken, customerID,isDeleted,threadId,sessionRe ,extension} = ChatViewContextInfo();
    const [messages, setMessages] = useState<any[]>([]);
    const params = new URLSearchParams(window.location.search)
    console.log(params,"params")
    console.log(accessToken ,extension,"userAgentRef,registererRef,sessionRef ,extension");
    
    return (
        <ChatBotWidget
        // apiKey="YOUR_OPENAI_API_KEY_HERE"
        chatIcon={<div>O</div>}
        chatbotName="UCX Support"
        isTypingMessage="Typing..."
        IncommingErrMsg="Oops! Something went wrong. Try again."
        primaryColor="rgb(14, 114, 237)"
        inputMsgPlaceholder="Send a Message"
        conversation={messages}
        handleNewMessage={setMessages}
        isSetChatOpen={isSetChatOpen}
        // callOpen={callOpen}
        chatClient={chatClient}
        chatThreadClient={chatThreadClient}
        />
    )
}

export default ChatWidget;